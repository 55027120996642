import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person"; // Importing PersonIcon from Material-UI Icons
import CustomizedProgressBars from "../CustomizedProgressBars";

const settings = ["My Application", "My Profile", "Change Password", "Logout"];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userData, setUserData] = React.useState(); // Initialize as null or an empty object
  const { contestId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    if (token) {
      fetchUserData(token.userId);
    }
  }, []);
  const handleLogout = () => {
    localStorage.clear();
    navigate(`/login/${contestId}`);
    toast.success("Logged Out successfully!", {
      position: "top-right",
      autoClose: 3000,
      theme: "colored",
    });
  };

  const handleChangepassword = () => {
    navigate(`/change-password/${contestId}`);
  };
  const fetchUserData = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application/${userId}`
      );
      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      } else {
        throw new Error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    }finally {
      setLoading(false);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuClick = (setting) => {
    handleCloseUserMenu();
    if (setting === "Logout") {
      handleLogout();
    } else if (setting === "Change Password") {
      handleChangepassword();
    } else if (setting === "My Application") {
      // Example: Redirect to /dashboard/${contestId}
      navigate(`/dashboard/${contestId}`);
    } else if (setting === "My Profile") {
      navigate(`/profile/${contestId}`);
    }
  };
  if (loading) {
    return (
      <>
        <CustomizedProgressBars />
      </>
    );
  }
  return (
    <AppBar
      position="static"
      sx={{
        background: "#43C6AC", // fallback for old browsers
        background: "-webkit-linear-gradient(to right, #191654, #43C6AC)", // Chrome 10-25, Safari 5.1-6
        background: "linear-gradient(to right, #191654, #43C6AC)", // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography> */}
          
          <img src="/iimcip-logo.png" alt="logo" width="50" />
         
         

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 0 }}>
            {userData ? (
              <Typography variant="body1" sx={{ mr: 2, color: "inherit" }}>
                Welcome, {userData.firstName} {userData.lastName} (Reg Id-{userData.registrationId})
              </Typography>
            ) : null}
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt="Subha"
                  src="/static/images/avatar/2.jpg"
                  sx={{
                    backgroundColor: "#0066ff", // Blue background color
                  }}
                >
                  <PersonIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleMenuClick(setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
