import React from "react";
import { Typography, Container, Grid, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Banner from "../Banner";

const PageNotFound = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Banner />
      <div
        style={{
          width: "100%",
          // height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "black", // Text color
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ minHeight: "80vh" }}
          >
            <Grid item xs={12} sm={6}>
              <img
                src="/404.png"
                alt="logo"
                width="100%"
                height="auto"
                style={{
                  maxWidth: "400px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" align="center">
                The page you are looking for might have been removed or temporarily unavailable.
              </Typography>
              {/* <Typography variant="body1" align="center" style={{ marginTop: "20px" }}>
                Go back to{" "}
                <Button
                  onClick={() => navigate(`/login/${id}`)}
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "#64B5F6",
                  }}
                >
                  Login
                </Button>
              </Typography> */}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default PageNotFound;
