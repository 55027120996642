import React from 'react';
import { Typography } from '@mui/material';

const Footer = () => {
  return (
    <footer
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#f0f0f0',
        padding: '10px 20px',
        textAlign: 'center', // Center the text
        borderTop: '1px solid #ddd',
        zIndex: 1000, // Ensure the footer is above other content
      }}
    >
      <Typography
      
      variant="body1"
      color="textSecondary"
      align="center"
      className="text-primary"
  
       >
        Copyright © 2024 IIM Calcutta Innovation Park
      </Typography>
    </footer>
  );
};

export default Footer;
