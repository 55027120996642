import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomizedProgressBars from "../CustomizedProgressBars";
import PageNotFound from "../404";
import Navbar from "../Navbar";
import Footer from "../Footer";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { contestId } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [checkStarted, setCheckStarted] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [errorpassword, setErrorPassword] = useState("");
  const [errorCpassword, setErrorCPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [urlValid, setUrlValid] = useState(false);
  const [loading, setLoading] = useState(true);

  const validationURL = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application/get-limited-contest-data/${contestId}`
      );
      if (response.ok) {
        setUrlValid(true);
      } else {
        setUrlValid(false);
        localStorage.clear();
        navigate(`/login/${contestId}`);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Please Check The URL, It`s Not a Valid Url", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validationURL();
  }, [contestId, navigate]);

  useEffect(() => {
    // Check if newPassword and confirmPassword match whenever they change and checkStarted is true
    if (checkStarted) {
      if (newPassword.length === 0) {
        setErrorPassword("Please enter a password");
      } else {
        setErrorPassword("");
      }
      if (confirmPassword.length === 0) {
        setErrorCPassword("Please enter a Confirm password");
      } else {
        setErrorCPassword("");
      }
    }
  }, [newPassword, confirmPassword, checkStarted]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCheckStarted(true); // Start checking password match
  
    // Check if newPassword or confirmPassword is empty
    if (newPassword.trim() === "" || confirmPassword.trim() === "") {
      toast.error("Please enter both New Password and Confirm Password", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    }
  
    // Check if newPassword and confirmPassword match
    if (newPassword !== confirmPassword) {
      toast.error("Both Passwords do not match", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
    } else {
      if (!user.accessToken) {
        console.error("Access token not available");
        toast.error("Access Token not Present", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
        });
        return;
      } else {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/auth/user-change-password`,
            {
              password: newPassword,
              formId: contestId,
            },
            {
              headers: {
                Authorization: `Bearer ${user.accessToken}`,
              },
            }
          );
  
          if (response.status >= 200 && response.status < 300) {
            toast.success("Password changed successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
            });
            navigate(`/dashboard/${contestId}`);
          } else {
            toast.error("Error Occured", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
            });
          }
        } catch (error) {
          console.error("Error changing password:", error);
          toast.error(error.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      }
    }
  };
  

  const handleGoBack = () => {
    navigate(`/dashboard/${contestId}`);
  };

  if (loading) {
    return <CustomizedProgressBars />;
  }

  return (
    <>
      {urlValid ? (
        <>
          <Navbar />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Paper elevation={3} sx={{ minWidth: 300, p: 3, mt: 13 }}>
              <Typography variant="h5" align="center" gutterBottom>
                Change Password
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="New Password"
                      type={showPassword ? "text" : "password"}
                      name="newPassword"
                      value={newPassword}
                      onChange={handleChange}
                      error={!!errorpassword}
                      helperText={errorpassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTogglePasswordVisibility}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={handleChange}
                      error={!!errorCpassword}
                      helperText={errorCpassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                  <Button onClick={handleGoBack} variant="contained"  sx={{ mr: 1 }}>
                    Back
                  </Button>
                  <Button type="submit" variant="contained"  sx={{ ml: 1 }}>
                    Submit
                  </Button>
                </Box>
                 
                </Grid>
              </form>
            </Paper>
          </Box>
        </>
      ) : (
        <>
          <PageNotFound />
        </>
      )}
      {/* <Footer/> */}
    </>
  );
};

export default ChangePassword;
