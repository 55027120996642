import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import Login from "./Components/Pages/Login";
import Register from "./Components/Pages/Register";
import Home from "./Components/Pages/Home";
import LoginWithId from "./Components/Pages/Login/LoginWithId";
import PageNotFound from "./Components/Pages/404";
import ForgotPassword from "./Components/Pages/ForgotPassword";
import ChangePassword from "./Components/Pages/ChangePassword";
import ResetPassword from "./Components/Pages/ResetPassword";
import FormPreview from "./Components/Pages/FormPreview";
import ProfilePage from "./Components/Pages/ProfilePage";
import Footer from "./Components/Pages/Footer";
import RedirectIfAuthenticated from "./Components/Redirect";

// Helper function to check user authentication and redirect
const checkAuthentication = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const currentPath = window.location.pathname;

  if (
    user &&
    user.accessToken &&
    user.contestId &&
    !currentPath.startsWith("/dashboard")
  ) {
    // window.location.href = `http://user.iimcipcontest.karmickdev.com/dashboard/${user.contestId}`;
    // window.location.href = `http://localhost:3001/dashboard/${user.contestId}`;
  }
};

const App = () => {
  useEffect(() => {
    checkAuthentication(); // Call the checkAuthentication function when the app starts
  }, []);

  // Check if the user is authenticated and if the contest ID matches
  const isAuthenticated = (contestId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user.contestId === contestId;
  };

  // Custom component to handle protected routes with contestId check
  const ProtectedRoute = ({ element: Component, ...rest }) => {
    const { contestId } = useParams();
    return isAuthenticated(contestId) ? (
      <Component {...rest} />
    ) : (
      <Navigate to={`/login/${contestId}`} />
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
        <Route
          path="/login/:id"
          element={
            <RedirectIfAuthenticated>
              <LoginWithId />
            </RedirectIfAuthenticated>
          }
        />
        <Route
          path="/register/:id"
          element={
            <RedirectIfAuthenticated>
              <Register />
            </RedirectIfAuthenticated>
          }
        />
        <Route
          path="/forgot-password/:id"
          element={
            <RedirectIfAuthenticated>
              <ForgotPassword />
            </RedirectIfAuthenticated>
          }
        />
        <Route
          path="/dashboard/:contestId"
          element={<ProtectedRoute element={Home} />}
        />
        <Route
          path="/dashboard/FormPreview/:contestId"
          element={<ProtectedRoute element={FormPreview} />}
        />
        <Route
          path="/change-password/:contestId"
          element={<ProtectedRoute element={ChangePassword} />}
        />
        <Route
          path="/profile/:contestId"
          element={<ProtectedRoute element={ProfilePage} />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;