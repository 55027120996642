import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import CustomizedProgressBars from "../CustomizedProgressBars";
import PageNotFound from "../404";
import Banner from "../Banner";
import Footer from "../Footer";
import { styled } from "@mui/material/styles";

const ResponsiveContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column", // Default to column for small screens
  alignItems: "center",
  justifyContent: "center", // Center vertically
  // minHeight: "100vh", // Full viewport height
  padding: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    flexDirection: "row", // Row layout for medium and up screens
  },
}));


const ResponsivePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: "100%",
  maxWidth: "800px",
  boxShadow: theme.shadows[4],
  border: "1px solid #ccc",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(3),
    maxWidth: "700px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const BackgroundImageBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(/fg.gif)`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "350px",
  width: "100%", // Full width on small screens
  borderRadius: "8px",
  position: "relative",
  overflow: "hidden",
  display: "flex", // Use Flexbox for centering
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    height: "250px", // Adjust height for medium screens
    width: "350px", // Adjust width for medium screens
    margin: "0 auto", // Center horizontally
  },
  [theme.breakpoints.down("sm")]: {
    height: "250px", // Adjust height for small screens
  },
  [theme.breakpoints.down("xs")]: {
    height: "200px", // Adjust height for extra small screens
    width: "100%", // Full width on extra small screens
  },
}));


const ResponsiveForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    gap: theme.spacing(1),
    width: "100%", // Full width for medium screens
  },
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
  },
}));

const ResponsiveButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [urlValid, setUrlValid] = useState(false);
  const [open, setOpen] = useState(false); // State to manage modal visibility
  const [modalMessage, setModalMessage] = useState(""); // State to manage modal message

  const validationURL = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application/get-limited-contest-data/${id}`
      );
      if (response.ok) {
        setUrlValid(true);
      } else {
        setUrlValid(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Please Check The URL, It`s Not a Valid Url", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validationURL();
  }, [id]);

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  useEffect(() => {
    if (submitted) {
      if (!validateEmail(email)) {
        setError("Please enter a valid email address");
      } else {
        setError("");
      }
    }
  }, [email, submitted]);

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    // Email validation
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/user-forgot-password`,
          {
            email: email,
            formId: id,
          }
        );
        if (response.status >= 200 && response.status < 210) {
          setModalMessage(
            "Password reset link has been sent to your email id.  Please check your inbox and follow the instruction."
          );
          setOpen(true); // Open the modal
        }
      } catch (error) {
        console.error(
          "Error changing password:",
          error?.response?.data?.message
        );
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
      }
    }
  };

  const handleback = () => {
    navigate(`/login/${id}`);
  };

  const handleLogin = () => {
    navigate(`/login/${id}`);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(`/login/${id}`);
  };

  if (loading) {
    return <CustomizedProgressBars />;
  }

  return (
    <>
      {urlValid ? (
        <>
          <Banner />

          <ResponsiveContainer sx={{mt:2}}>
            <ResponsivePaper elevation={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <BackgroundImageBox />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    marginBottom={3}
                    marginTop={1}
                  >
                    <Typography variant="h4" color="primary" align="center">
                      <strong>Forgot Password</strong>
                    </Typography>
                  </Grid>
                  <ResponsiveForm onSubmit={handleSubmit}>
                    <TextField
                      label="Email"
                      value={email}
                      onChange={handleChange}
                      fullWidth
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <EmailIcon sx={{ mr: 1, maxWidth: "18px" }} />
                        ),
                      }}
                      error={!!error}
                      helperText={error}
                    />
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item>
                        <ResponsiveButton
                          onClick={handleback}
                          variant="contained"
                          color="primary"
                        >
                          Back
                        </ResponsiveButton>
                      </Grid>
                      <Grid item>
                        <ResponsiveButton
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </ResponsiveButton>
                      </Grid>
                    </Grid>
                  </ResponsiveForm>
                  <Grid container justifyContent="center" sx={{ mt: 2 }}>
                    <Typography variant="body1" align="center">
                      Remember your password?{" "}
                      <Link onClick={handleLogin} sx={{ cursor: "pointer" }}>
                        Login
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </ResponsivePaper>
          </ResponsiveContainer>
        </>
      ) : (
        <PageNotFound />
      )}
      <Footer />

      {/* Modal for password reset link message */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Password Reset</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ForgotPassword;
