import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const RedirectIfAuthenticated = ({ children }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.contestId === id) {
      navigate(`/dashboard/${id}`);
    }
  }, [navigate, id]);

  return children;
};

export default RedirectIfAuthenticated;
