import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  Link,
  InputAdornment,
  Box,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import CustomizedProgressBars from "../CustomizedProgressBars";
import PageNotFound from "../404";
import Banner from "../Banner";
import Footer from "../Footer";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ResponsiveContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(10),
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(5),
  },
}));

const ResponsivePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  minWidth: "300px",
  width: "100%",
  maxWidth: "800px",
  boxShadow: theme.shadows[4],
  border: "1px solid #ccc",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const BackgroundImageBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(/bg.jpg)`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "350px",
  borderRadius: "8px",
  position: "relative",
  overflow: "hidden",
  display: "flex", // Use Flexbox for centering
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    height: "250px", // Adjust height for medium screens
    width: "350px", // Adjust width for medium screens
    margin: "0 auto", // Center horizontally
  },
  [theme.breakpoints.down("sm")]: {
    height: "250px", // Adjust height for small screens
  },
  [theme.breakpoints.down("xs")]: {
    height: "200px", // Adjust height for extra small screens
    width: "100%", // Full width on extra small screens
  },
}));
const LoginWithId = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Extracting the ID from the URL params
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errorEmail, setEmailError] = useState("");
  const [errorPassword, setPasswordError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [urlValid, setUrlValid] = useState(false);
  const validationURL = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application/get-limited-contest-data/${id}`
      );

      if (response.ok) {
        setUrlValid(true);
      } else {
        setUrlValid(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Please Check The URL, It`s Not a Valid Url", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validationURL();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };
  const validatePassword = (value) => {
    return value.trim() !== ""; // Check if the password is not blank
  };

  useEffect(() => {
    if (submitted) {
      if (!validateEmail(formData.email)) {
        setEmailError("Please enter a valid email address");
      } else {
        setEmailError("");
      }

      if (!validatePassword(formData.password)) {
        setPasswordError("Password Cannot be empty");
      } else {
        setPasswordError("");
      }
    }
  }, [formData.email, formData.password, submitted]);

  if (loading) {
    return (
      <>
        <CustomizedProgressBars />
      </>
    );
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!validateEmail(formData.email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    // Check if the password is not blank
    if (!validatePassword(formData.password)) {
      setPasswordError("Password cannot be blank");
      return;
    }

    try {
      // Send a POST request to the backend for user login
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/user-login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email,
            password: formData.password,
            formId: id,
          }),
        }
      );

      if (response.ok) {
        // If login is successful, extract the accessToken and publicId from the response
        const data = await response.json();
        const { accessToken, publicId } = data;

        // Create a user object with necessary details
        const user = {
          accessToken: accessToken,
          userId: publicId,
          contestId: id,
        };

        // Store the user object in local storage
        localStorage.setItem("user", JSON.stringify(user));

        // Navigate to the home page
        navigate(`/dashboard/${id}`);

        // Perform a hard refresh
        window.location.reload(true);
        toast.success("Login Successfull", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
      } else {
        // If response is not okay, handle error
        const responseData = await response.json(); // Parse response JSON
        throw new Error(responseData.message || "Failed to login"); // Throw error with message from server or default message
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
      // Handle login error (e.g., show error message to the user)
    }
  };

  // if (isLoading) {
  //   return <Typography>Loading...</Typography>;
  // }
  const handleRegister = () => {
    // Handle registration logic here, for example, navigate to the registration page
    navigate(`/register/${id}`);
  };
  const handleForgot = () => {
    navigate(`/forgot-password/${id}`);
  };
  return (
    <>
      {urlValid ? (
        <>
          <Banner />
          <Grid sx={{ marginBottom: 5, mt: -8 }}>
            <ResponsiveContainer>
              <ResponsivePaper>
                <Grid
                  container
                  spacing={2}
                  direction={{ xs: "column", md: "row" }}
                >
                  {/* Left side */}
                  <Grid item xs={12} md={6}>
                    <BackgroundImageBox />
                  </Grid>

                  {/* Right side */}
                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      justifyContent={"center"}
                      spacing={2}
                      marginBottom={3}
                    >
                      <Typography variant="h3" color="#0f0c29">
                        <strong>Login</strong>
                      </Typography>
                    </Grid>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2} direction="column">
                        <Grid item xs={12}>
                          <TextField
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                            size="small"
                            variant="outlined"
                            autoComplete="off"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon sx={{ maxWidth: "18px" }} />
                                </InputAdornment>
                              ),
                            }}
                            error={!!errorEmail}
                            helperText={errorEmail}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            fullWidth
                            size="small"
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            autoComplete="off"
                            error={!!errorPassword}
                            helperText={errorPassword}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon sx={{ maxWidth: "18px" }} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  onClick={handleTogglePasswordVisibility}
                                  style={{ cursor: "pointer" }}
                                >
                                  {showPassword ? (
                                    <Visibility sx={{ maxWidth: "18px" }} />
                                  ) : (
                                    <VisibilityOff sx={{ maxWidth: "18px" }} />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="center" sx={{ mt: 3 }}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="success"
                        >
                          Login
                        </Button>
                      </Grid>
                    </form>

                    <Grid container justifyContent="center" sx={{ mt: 3 }}>
                      <Typography variant="body1">
                        Don't have an account?{" "}
                        <Link
                          onClick={handleRegister}
                          color="primary"
                          sx={{ cursor: "pointer" }}
                        >
                          Create an Account
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="center" sx={{ mt: 3 }}>
                      <Link
                        onClick={handleForgot}
                        color="primary"
                        sx={{ cursor: "pointer" }}
                      >
                        Forgot Password?
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </ResponsivePaper>
            </ResponsiveContainer>

            <Footer />
          </Grid>
        </>
      ) : (
        <>
          <PageNotFound />
        </>
      )}
    </>
  );
};

export default LoginWithId;
